import type { AppRouter } from '@foodlista/backend'
import { createTRPCClient, httpBatchLink } from '@trpc/client'
import { getBackendUrl } from '../common/utils'

export function getTRPCCLient(token?: string) {
  const trpcURL = getTRPCURL()

  return createTRPCClient<AppRouter>({
    links: [
      httpBatchLink({
        url: trpcURL,
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }),
    ],
  })
}

export function getTRPCURL() {
  return `${getBackendUrl()}/trpc`
}
