import { defineCanFunction } from '@foodlista/access-control'
import { useQuery } from '@tanstack/vue-query'
import { useCachedFetch } from '~/domains/common/composables/useCachedFetch'

export const USERS_CACHE_KEYS = {
  currentUserProfile: 'users/me',
  restaurants: ['users', 'me', 'restaurants'],
  ownedRestaurants: ['users', 'me', 'restaurants', 'owned'],
  invitations: ['users', 'me', 'invitations'],
  identities: ['users', 'me', 'identities'],
}

export async function useGetCurrentUserProfile() {
  const user = await useCachedFetch('/api/users/me', {
    key: USERS_CACHE_KEYS.currentUserProfile,
  })

  const userWithAbilities = computed(() => {
    const can = defineCanFunction(user.value?.abilities || [])

    return {
      ...user.value,
      can,
    }
  })

  return userWithAbilities
}

export function useGetCurrentUserIdentities() {
  const { $trpc } = useNuxtApp()
  return useQuery({
    queryFn: () => $trpc.users.me.identities.list.query(),
    queryKey: USERS_CACHE_KEYS.identities,
  })
}

export function useGetCurrentUserInvitations() {
  const { $trpc } = useNuxtApp()
  return useQuery({
    queryKey: USERS_CACHE_KEYS.invitations,
    queryFn: () => $trpc.users.me.invitations.query(),
  })
}

export function useGetUserRestaurants() {
  const { $trpc } = useNuxtApp()

  return useQuery({
    queryFn: () => $trpc.users.me.restaurants.all.query(),
    queryKey: USERS_CACHE_KEYS.restaurants,
  })
}

export function useGetUserOwnedRestaurants() {
  const { $trpc } = useNuxtApp()

  return useQuery({
    queryFn: () => $trpc.users.me.restaurants.owned.query(),
    queryKey: USERS_CACHE_KEYS.ownedRestaurants,
  })
}
