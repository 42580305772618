<script setup lang="ts">
import CookieBanner from '~/domains/cookies/components/CookieBanner.vue'

const PWA_PROMPT_KEY = 'pwa-prompt-dismissed'

const route = useRoute()

const { t } = useI18n()

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const { siteName } = useAppConfig()

const { $pwa } = useNuxtApp()

function triggerPwaPrompt() {
  if (!$pwa || $pwa.isPWAInstalled || !$pwa.showInstallPrompt) {
    return
  }

  const wasPwaPromptDismissed = localStorage.getItem(PWA_PROMPT_KEY) === 'true'
  if (wasPwaPromptDismissed) {
    return
  }

  $pwa.install().then((choice) => {
    if (choice?.outcome) {
      localStorage.setItem(PWA_PROMPT_KEY, 'true')
    }
  })
}

const title = computed(() =>
  route.meta.title
    ? t('shared.seo.title', { title: t(route.meta.title as string) })
    : siteName,
)
</script>

<template>
  <Html :lang="head.htmlAttrs!.lang" :dir="head.htmlAttrs!.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
      <Link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <Link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <Link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <NuxtPwaManifest />
      <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#f43f5e" />
      <Meta name="msapplication-TileColor" content="#f43f5e" />
      <Meta name="theme-color" content="#f43f5e" />
    </Head>
    <Body
      :onclick="triggerPwaPrompt"
    >
      <NuxtLayout>
        <NuxtLoadingIndicator color="#f43f5e" />
        <NuxtPage />
        <UNotifications />
        <CookieBanner />
      </NuxtLayout>
    </Body>
  </Html>
</template>
