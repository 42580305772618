import slugify from 'slugify'

export function getBackendUrl() {
  slugify.extend({ '/': '-' })
  const runtimeConfig = useRuntimeConfig()
  const vercelEnv = runtimeConfig.public.vercelEnv

  if (vercelEnv === 'preview') {
    const vercelProject = runtimeConfig.public.vercelProject
    const commitRef = slugify(runtimeConfig.public.commitRef as string || '', {
      lower: true,
      strict: true,
    })
    return `https://${vercelProject}-git-${commitRef}-vlk-studio.vercel.app`
  }

  return runtimeConfig.public.backendUrl
}
