import type { UseFetchOptions } from '#app'
import type { NitroFetchRequest, TypedInternalResponse } from 'nitropack'

export async function useCachedFetch<Url extends NitroFetchRequest>(
  url: Url,
  options?: UseFetchOptions<Url>,
) {
  const nuxtApp = useNuxtApp()

  const { error, data } = await useFetch(url, {
    deep: false,
    dedupe: 'defer',
    getCachedData(key) {
      return (nuxtApp.payload.data[key]
        || nuxtApp.static.data[key]) as TypedInternalResponse<Url, unknown, 'get'> | null
    },
    ...(options as any),
  })

  if (error.value) {
    throw error.value
  }

  return computed(() => data.value!)
}
