<script setup lang="ts">
import { usePostHogClient } from '~/domains/analytics/composables/usePostHogClient'

const posthog = usePostHogClient()

const shouldShow = ref(false)

onMounted(() => {
  shouldShow.value = !posthog?.has_opted_out_capturing() && !posthog?.has_opted_in_capturing()
})

function declineCookies() {
  shouldShow.value = false

  posthog?.opt_out_capturing()
}

function acceptCookies() {
  shouldShow.value = false

  posthog?.opt_in_capturing()
}
</script>

<template>
  <div
    v-if="shouldShow"
    class="pointer-events-none fixed inset-0 z-50 flex h-dvh w-dvw flex-col items-center justify-end p-4"
  >
    <div
      class="pointer-events-auto w-full max-w-lg rounded-lg border bg-white/20 p-4 shadow-lg backdrop-blur-md dark:border-gray-800 dark:bg-black/20"
    >
      <div class="mx-auto flex max-w-6xl flex-col justify-between gap-4">
        <div>
          <p class="text-sm">
            {{ $t("cookies.text") }}
          </p>
        </div>
        <div class="flex gap-2 self-end">
          <UButton color="gray" variant="ghost" size="sm" @click="declineCookies">
            {{ $t("cookies.decline") }}
          </UButton>
          <UButton color="primary" size="sm" @click="acceptCookies">
            {{ $t("cookies.accept") }}
          </UButton>
        </div>
      </div>
    </div>
  </div>
</template>
