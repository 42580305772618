import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry, siteUrl } } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  if (import.meta.env.DEV) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
    tracesSampleRate: 0.2,
    tracePropagationTargets: ['localhost', siteUrl],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
})
