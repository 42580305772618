export default defineAppConfig({
  siteName: 'Foodlista',
  ui: {
    primary: 'rose',
    gray: 'zinc',
    button: {
      default: {
        size: 'lg',
        loadingIcon: 'i-tabler-loader-2',
      },
      color: {
        gray: {
          solid: 'ring-0 bg-gray-100',
        },
      },
      block: 'shrink',
    },
    input: {
      base: 'text-sm',
      default: {
        size: 'lg',
        loadingIcon: 'i-tabler-loader-2',
      },
    },
    select: {
      default: {
        loadingIcon: 'i-tabler-loader-2',
        size: 'lg',
      },
    },
    modal: {
      container: 'h-[100svh]',
      padding: 'p-0 pt-24',
      base: 'max-h-full md:max-h-[60vh] overflow-y-auto p-0 rounded-xl',
    },
    accordion: {
      item: {
        padding: 'px-3 py-4',
      },
    },
    alert: {
      description: 'mt-1 text-sm leading-5 opacity-90',
    },
  },
  // https://github.com/nuxt-modules/icon/issues/117
  nuxtIcon: {},
})
