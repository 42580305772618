import { useCachedFetch } from '~/domains/common/composables/useCachedFetch'

export const AUTH_CACHE_KEYS = {
  getUserSession: 'auth/me',
}

export async function useGetUserSession() {
  return await useCachedFetch('/api/auth/me', {
    key: AUTH_CACHE_KEYS.getUserSession,
  })
}
