import type { AppRouter } from '@foodlista/backend'
import { AUTH_COOKIE_NAME } from '@foodlista/shared/auth/constants'
import { getSupabaseAccessTokenFromCookie } from '@foodlista/shared/auth/utils'
import { createTRPCClient, httpBatchLink } from '@trpc/client'
import { getTRPCURL } from '~/domains/trpc/utils'

export default defineNuxtPlugin(() => {
  const baseCookie = useCookie(AUTH_COOKIE_NAME)

  const cookie = computed(() => {
    const cookies = baseCookie.value ? [baseCookie.value] : []

    for (let i = 0; true; i++) {
      const cookie = useCookie(`${AUTH_COOKIE_NAME}.${i}`)

      if (!cookie.value) {
        break
      }

      cookies.push(cookie.value)
    }

    return cookies.join('')
  })

  const token = computed(() => getSupabaseAccessTokenFromCookie(cookie.value))

  const trpcURL = getTRPCURL()

  const customFetch: typeof fetch = (url, init) => {
    return fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        ...(token.value ? { Authorization: `Bearer ${token.value}` } : {}),
      },
    })
  }

  const trpc = createTRPCClient<AppRouter>({
    links: [
      httpBatchLink({
        url: trpcURL,
        fetch: customFetch,
      }),
    ],
  })

  return {
    provide: {
      trpc,
    },
  }
})
