import type {
  DehydratedState,
  VueQueryPluginOptions,
} from '@tanstack/vue-query'

import {
  dehydrate,
  hydrate,
  MutationCache,
  QueryClient,
  VueQueryPlugin,
} from '@tanstack/vue-query'
import { USERS_CACHE_KEYS } from '~/domains/users/queries'

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query')

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10000,
        throwOnError: true,
      },
    },
    mutationCache: new MutationCache({
      onSuccess: async () => {
        await refreshNuxtData(USERS_CACHE_KEYS.currentUserProfile)
      },
    }),
  })

  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (import.meta.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient)
    })
  }

  if (import.meta.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value)
    })
  }
})
