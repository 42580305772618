import { usePostHogClient } from '~/domains/analytics/composables/usePostHogClient'

export const DEFAULT_FLAGS = {
  qr_stand_order: false,
}

export type FeatureFlag = keyof typeof DEFAULT_FLAGS

export function useFeatureFlag(flagId: FeatureFlag) {
  const featureFlags = useFeatureFlags()

  return computed(() => featureFlags.value[flagId])
}

export function useFeatureFlags() {
  const posthog = usePostHogClient()
  const featureFlags = useState<Record<FeatureFlag, boolean>>('featureFlags', () => DEFAULT_FLAGS)

  function syncFeatureFlags() {
    const flags = Array.from(new Set([
      ...Object.keys(DEFAULT_FLAGS),
      ...(posthog?.featureFlags.getFlags() ?? []),
    ])) as FeatureFlag[]

    flags.forEach((key) => {
      const value = posthog?.getFeatureFlagPayload(key)
      featureFlags.value[key] = toBoolean(value)
    })
  }

  onMounted(() => {
    posthog?.featureFlags.onFeatureFlags(syncFeatureFlags)
  })

  return featureFlags
}

function toBoolean(value: unknown): boolean {
  if (typeof value === 'boolean') {
    return value
  }

  if (typeof value === 'string') {
    return value.toLowerCase() === 'true'
  }

  return false
}
