export function getSupabaseAccessTokenFromCookie(cookie: string) {
	const jsonString = cookie.startsWith("base64-")
		? atob(cookie.replace("base64-", ""))
		: cookie

	try {
		const payload = JSON.parse(jsonString) as {
			access_token: string
		}

		return payload.access_token
	} catch {
		return undefined
	}
}
