import { useGetCurrentUserProfile } from '~/domains/users/queries'

export function usePostHogClient() {
  const { $posthog } = useNuxtApp()

  useGetCurrentUserProfile()
    .then((profile) => {
      const posthog = $posthog?.()
      if (!profile.value || !posthog) {
        return
      }

      const user = profile.value

      posthog.identify(user.id, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        plan: user.subscription?.plan,
      })
    })
    .catch(() => undefined)

  return $posthog?.() || undefined
}
